@import 'scss/custom-theme.scss';
@import 'scss/normalize.scss';
@import 'scss/reset.scss';

$red: #D40511;

:root {
  --postyellow: #FFCC00;
  --cloneyellow: #ffdd60;
  --dhlred: #{$red};
  --dhlred-dark: #{darken($red, 8%)};
  --dhlred-darker: #{darken($red, 16%)};
  --green: #32CD32;
  --black: #000000;
  --white: #FFFFFF;
  --gray80: #333333;
  --gray60: #666666;
  --gray45: #8c8c8c;
  --gray30: #b2b2b2;
  --gray20: #cccccc;
  --gray10: #e5e5e5;
  --gray08: #ebebeb;
  --gray05: #f2f2f2;
  --gray02: #f4f4f4;
}

@font-face {
  font-family: 'Delivery';
  src: url('assets/fonts/Delivery_W_Lt.woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Delivery';
  src: url('assets/fonts/Delivery_W_LtIt.woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Delivery';
  src: url('assets/fonts/Delivery_W_Rg.woff');
}

@font-face {
  font-family: 'Delivery';
  src: url('assets/fonts/Delivery_W_It.woff');
  font-style: italic;
}

@font-face {
  font-family: 'Delivery';
  src: url('assets/fonts/Delivery_W_Bd.woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Delivery';
  src: url('assets/fonts/Delivery_W_BdIt.woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Delivery Condensed';
  src: url('assets/fonts/Delivery_W_CdLt.woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Delivery Condensed';
  src: url('assets/fonts/Delivery_W_CdBlk.woff');
  font-weight: 900;
}

main {
  padding: 6rem 0 3rem 0;
}

mat-form-field {
  width: 100%;
}

hot-toast-container {
  position: absolute;
  z-index: 1000000;
}

table.mat-table {
  width: 100%;
  border: .1rem solid var(--gray10);
  border-bottom: none;
}

tr.mat-row:hover {
  background-color: var(--gray10);
}

td.mat-cell{
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem !important;
  font-size: 1.2rem;
}

.mat-column-actions {
  overflow: visible !important;
}

td.mat-cell.cdk-column-actions {
  text-align: right;
  padding-right: 6px !important;
  min-width: 90px;
}

.cdk-overlay-container {
  z-index: 100000;
}

.dhl-link {
  color: var(--dhlred);
  text-decoration: underline;
}

.mbl-dup{
  color: var(--dhlred);
  font-weight: bold;
}

ngx-dropzone {
  height: 120px !important;
}

header {
  position: sticky;
  left: 2rem;
  width: calc(100vw - 6rem);
}

th.mat-header-cell {
  font-weight: bold;
}
